
// import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import { Mixins } from 'vue-property-decorator'
import FormMixin from '@/mixins/FormMixin.vue'
import ValidationMixin from '@/mixins/ValidationMixin.vue'

@Component({
  name: 'PasswordChangeForm'
})

export default class PasswordChangeForm extends Mixins(FormMixin, ValidationMixin) {
  form = {
    password: '',
    repeatPassword: ''
  }

  showPassword = false
}
