
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
// import _ from 'lodash'

import PasswordChangeForm from '@/views/Auth/components/PasswordChangeForm.vue'
import Auth from '@/apis/AuthApi'
import { Ref } from 'vue-property-decorator'
import { VForm } from '@/mixins/FormMixin.vue'

@Component({
  name: 'PasswordChange',
  components: {
    PasswordChangeForm
  }
})

export default class PasswordChange extends Vue {
  @Ref() readonly passwordChangeForm!: VForm
  isValidForm = false
  email = ''

  title = 'Новый пароль'
  subtitle = 'Необходимо задать новый пароль для входа'

  get buttonText(): string {
    return 'Сохранить'
  }

  created() {
    // @ts-ignore
    this.email = this.$route.query.email
    const tokenName = this.$route.path.indexOf('change') !== -1 ? 'change-password' : 'set-password'

    Auth.checkToken(tokenName, this.email)
      .then(response => {
        if (!response.data.hasToken) {
          this.$router.push({ name: 'Login', params: { userRole: this.$store.getters['auth/userRole'] } })
        }
      })
      .catch(() => {
        this.$router.push({ name: 'Login', params: { userRole: this.$store.getters['auth/userRole'] } })
      })

    if (this.$route.path.indexOf('change') === -1) {
      this.title = 'Персональный пароль'
      this.subtitle = 'Для завершения регистрации придумайте пароль, который будете использовать для входа в свой Личный кабинет'
    }
  }

  changePassword() {
    Auth.changePassword(this.email, this.passwordChangeForm.form.password)
      .then(({ data }) => {
        this.$store.commit('auth/SET_AUTHENTICATED', true)
        this.$store.commit('auth/SET_USER', data)
        this.$store.commit('auth/SET_TOKEN', data.token)
        this.$router.push({ name: 'Home' })
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Что-то пошло не так, повторите позже')
      })
  }
}
